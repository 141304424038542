import Vue from 'vue';
import VueRouter from 'vue-router';
import apolloProvider from '@/apollo';
import { getToken, onLogout } from '@/vue-apollo';
import store from '@/store/index';
import { i18n } from '../i18n';


const apollo = apolloProvider.defaultClient;

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Login.vue'),
    meta: {
      forVisitors: true,
    },
    alias: '/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/IndexView.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.dashboard'),
      },
    },
    children: [
      {
        path: '',
        name: 'dashboard-conversations',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/ConversationsView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'agents',
        name: 'dashboard-agents',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/AgentsView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'messages',
        name: 'dashboard-messages',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/MessagesView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'bot',
        name: 'conversations-ivr',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/BotView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'store',
        name: 'dashboard-store',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/DashboardStore.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'orders',
        name: 'order-list-store',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard/OrderListStore.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'summary',
        name: 'conversations-summary',
        component: () => import(/* webpackChunkName: "dashboard.summary" */ '../views/Dashboard/SummaryView.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
    ],
  },
  {
    path: '/send-templates',
    name: 'templates',
    component: () => import(/* webpackChunkName: "templates" */ '../views/SendTemplates.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('templates.title'),
      },
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notification.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.notifications'),
      },
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/Campaigns.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.campaigns'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/settings',
    name: 'campaigns-settings',
    component: () => import(/* webpackChunkName: "dashboard/settings" */ '../views/CampaignsSettings.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.campaign-settings'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/channels-settings',
    name: i18n.t('campaigns.cards.channels-settings'),
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/CampaignChannelsSettings/CampaignChannelsSettings.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('campaigns.cards.channels-settings'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/assign-users',
    name: 'campaigns-assign-users',
    component: () => import(/* webpackChunkName: "campaigns/assign-users" */ '../views/CampaignsAssignUsers.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.assign-user'),
      },
    },
  },
  {
    path: '/campaigns/:campaign_id/blocked-clients',
    name: 'campaigns-blocked-clients',
    component: () => import(/* webpackChunkName: "campaigns/blocked-clients" */ '../views/CampaignsBlockedClients.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.blocked-clients'),
      },
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.users'),
      },
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.messages'),
      },
    },
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.team'),
      },
    },
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: () => import(/* webpackChunkName: "team" */ '../views/Permissions.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.permissions'),
      },
    },
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import(/* webpackChunkName: "configuration" */ '../views/Configuration.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.configuration'),
      },
    },
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '../views/Companies.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.company'),
      },
    },
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: () => import(/* webpackChunkName: "company-send" */ '../views/CompaniesShipping.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.company-send'),
      },
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName "reports" */ '../views/Reports.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.reports'),
      },
    },
  },
  {
    path: '/store',
    name: 'store-sellia',
    component: () => import(/* webpackChunkName "store.sellia" */ '../views/Shop/Index.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.store'),
      },
    },
  },
  {
    path: '/store/:id/edit',
    name: 'store.admin.sellia',
    component: () => import(/* webpackChunkName "store.sellia" */ '../views/Shop/DetailStore.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.store'),
      },
    },
  },
  {
    path: '/store/:id/mobile',
    name: 'store.mobile.sellia',
    component: () => import(/* webpackChunkName "store.sellia" */ '../views/Shop/MobileView.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.mobile'),
      },
    },
  },
  {
    path: '/store/:id/categories',
    name: 'store-categorie.admin.sellia',
    component: () => import(/* webpackChunkName "store-categorie.sellia" */ '../views/Shop/Categories.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
    },
  },
  {
    path: '/store/:id/product/:idProduct',
    name: 'store-product.admin.sellia',
    component: () => import(/* webpackChunkName: "store-product.sellia" */ '../views/Shop/Product/Index.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
    },
    props: true,
    children: [
      {
        path: '',
        name: 'store.product-info',
        component: () => import(/* webpackChunkName: "invite.sellia" */ '../views/Shop/Product/Info.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      }, {
        path: 'options',
        name: 'store.product-options',
        component: () => import(/* webpackChunkName: "invite.sellia" */ '../views/Shop/Product/OptionsGroup.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      }, {
        path: 'attributes',
        name: 'store.product-attributes',
        component: () => import(/* webpackChunkName: "invite.sellia" */ '../views/Shop/Product/Attributes.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      }, {
        path: 'variants',
        name: 'store.product-variants',
        component: () => import(/* webpackChunkName: "invite.sellia" */ '../views/Shop/Product/Variants.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      },
      {
        path: 'wholesale-price',
        name: 'store.wholesale-price',
        component: () => import(/* webpackChunkName: "store.wholesale-price" */ '../views/Shop/Product/Wholesale.vue'),
        meta: {
          forAuth: true,
          layout: 'navigation-layout',
        },
      }, // {
      //   path: 'promotion',
      //   name: 'store.product-promotion',
      //   component: () => import(/* webpackChunkName:
      // "invite.sellia" */ '../views/Shop/Product/Promotion.vue'),
      //   meta: {
      //     forAuth: true,
      //     layout: 'navigation-layout',
      //   },
      // },
    ],
  },
  {
    path: '/store/:id/:client/cart',
    name: 'store-cart.admin.sellia',
    component: () => import(/* webpackChunkName "store.sellia" */ '../views/Shop/StoreCart.vue'),
    meta: {
      public: true,
      layout: 'without-layout',
    },
  },
  {
    path: '/store/:id/:client',
    name: 'invite.sellia',
    component: () => import(/* webpackChunkName: "invite.sellia" */ '../views/Shop/ShowStore.vue'),
    meta: {
      public: true,
      layout: 'without-layout',
    },
  },
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import(/* webpackChunkName: "styleguide" */ '../styleguide/index.vue'),
    meta: {
      forAuth: true,
      layout: 'navigation-layout',
      site: {
        name: i18n.t('app.style-guide'),
      },
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/PageNotFound.vue'),
    meta: {
      forAuth: false,
      layout: 'without-layout',
      site: {
        name: '404',
      },
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login' && to.query.data) {
    await onLogout(apollo);
  }

  const isAuthenticated = store.getters['dashboard/getAccessToken'];
  const token = !!getToken();


  if (to.matched.some(record => record.meta.forVisitors) && isAuthenticated && token) {
    return next('/dashboard');
  }
  if (to.matched.some(record => record.meta.forAuth) && (!isAuthenticated || !token)) {
    return next('/');
  }

  if (to.name === 'users' || to.name === 'company') {
    const user = store.getters['shared/userInfo'];
    if (user.role !== 'owner' && to.name === 'company') {
      return next('*');
    }
    if (user.role !== 'owner' && user.role !== 'super') {
      return next('*');
    }
  }

  return next();
});

export default router;
