var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea-container"},[(_vm.label)?_c('label',{staticClass:"textarea__label",class:_vm.requiredInput && 'input__label-required'},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"textarea__control",class:[
      { error: _vm.error, disabled: _vm.disabled, 'read-only': _vm.readonly },
      _vm.customContainerClass,
    ]},[_c('textarea',_vm._g(_vm._b({ref:"hccTextarea",class:[{ disabled: _vm.disabled, resizable: _vm.resizable }, _vm.customClass],attrs:{"name":_vm.name,"cols":_vm.cols,"rows":_vm.rows,"readonly":_vm.readonly || _vm.disabled},domProps:{"value":_vm.value}},'textarea',_vm.$attrs,false),{
        ..._vm.$listeners,
        input: (event) => _vm.$emit('input', event.target.value),
      })),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"icon error"},[_c('alert-circle')],1):_vm._e()]),(_vm.error && _vm.errorMessage)?_c('div',{staticClass:"input__error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }