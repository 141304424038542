var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{ref:"clickBtn",staticClass:"button-icon",class:[
    _vm.color,
    _vm.size,
    { muted: _vm.isMuted, round: _vm.round, square: _vm.square },
    { 'square-gray': _vm.color === 'gray-light' },
    { disabled: _vm.unclickable },
  ],attrs:{"disabled":_vm.disabled},on:{"click":_vm.animateRipple}},[_vm._t("default"),_c('transition-group',_vm._l((_vm.activeRipples),function(val,i){return _c('span',{key:'icon-button-effect' + i,ref:'icon-button-effect' + i,refInFor:true,staticClass:"icon-button-effect",style:({ top: val.y + 'px', left: val.x + 'px' }),on:{"animationend":function($event){return _vm.rippleEnd(i)}}})}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }